mutation RegisterInvitation($input: RegisterInvitationInput!) {
  registerInvitation(input: $input) {
    ... on AccessToken {
      accessToken
    }
    ... on Mfa {
      mfaToken
      phoneNumber
      redirectPath
    }
  }
}
