mutation SignIn(
  $email: String!
  $password: String!
  $clientId: String!
  $clientSecret: String!
) {
  signIn(
    email: $email
    password: $password
    clientId: $clientId
    clientSecret: $clientSecret
  ) {
    ... on AccessToken {
      accessToken
      redirectUri
      resourceOwnerId
    }
    ... on Mfa {
      mfaChannel
      mfaToken
      phoneNumber
      redirectPath
    }
  }
}
