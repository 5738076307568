import React from 'react'
import { useLocation } from 'react-router-dom'

import MFA_CHECK_VERIFICATION_CODE_MUTATION from '@app/account/graphql/MfaCheckVerificationCodeMutation.gql'
import MFA_SEND_VERIFICATION_CODE_MUTATION from '@app/account/graphql/MfaSendVerificationCodeMutation.gql'
import { useI18n } from '@app/configuration/contexts/I18nContext'
import useCustomer from '@app/configuration/hooks/useCustomer'
import {
  AccessToken,
  Mfa,
  MfaCheckVerificationCodeMutation,
  MfaCheckVerificationCodeMutationVariables,
  MfaSendVerificationCodeMutation,
  MfaSendVerificationCodeMutationVariables,
} from '@app/graphql-types/Account'
import CheckVerificationCodeForm from '@app/src/components/forms/Mfa/CheckVerificationCodeForm'
import { useMutationResult } from '@app/src/hooks/useMutationResult'

const CheckVerificationCodePage = () => {
  const i18n = useI18n()
  const location = useLocation()
  const { clientId, clientSecret } = useCustomer()

  const defaultCheckErrorMessage = i18n.t('mfa.verifyCodeErrorMessage')
  const defaultResendErrorMessage = i18n.t('mfa.sendCodeErrorMessage')

  const { mutate: checkMutation } = useMutationResult<
    MfaCheckVerificationCodeMutation,
    MfaCheckVerificationCodeMutationVariables,
    AccessToken
  >({
    mutation: MFA_CHECK_VERIFICATION_CODE_MUTATION,
    successField: 'mfaCheckVerificationCode',
  })

  const { mutate: sendMutation } = useMutationResult<
    MfaSendVerificationCodeMutation,
    MfaSendVerificationCodeMutationVariables,
    Mfa
  >({
    mutation: MFA_SEND_VERIFICATION_CODE_MUTATION,
    successField: 'mfaSendVerificationCode',
  })

  const handleSubmit = React.useCallback(
    (formData: { code: string }, setErrorText: (errorText: string) => void) => {
      setErrorText('')
      checkMutation({
        clientId,
        clientSecret,
        code: formData.code,
        mfaChannel: location.state?.mfaChannel,
        mfaToken: location.state?.mfaToken,
        phone: location.state?.phone,
      })
        .then(({ errors }) => {
          if (errors?.length) {
            const msg = errors[0].message || defaultCheckErrorMessage
            setErrorText(msg)
            return
          }

          window.location.href = '/'
        })
        .catch((_serverError) => {
          setErrorText(defaultCheckErrorMessage)
        })
    },
    [clientId, clientSecret, checkMutation, defaultCheckErrorMessage, location],
  )

  const handleResendCode = React.useCallback(
    (setErrorText: (errorText: string) => void) => {
      setErrorText('')
      sendMutation({
        mfaChannel: location.state?.mfaChannel,
        mfaToken: location.state?.mfaToken,
        phone: location.state?.phone,
      })
        .then(({ errors }) => {
          if (errors?.length) {
            const msg = errors[0].message || defaultResendErrorMessage
            setErrorText(msg)
            return
          }
        })
        .catch((_serverError) => {
          setErrorText(defaultResendErrorMessage)
        })
    },
    [defaultResendErrorMessage, location.state, sendMutation],
  )

  return (
    <CheckVerificationCodeForm
      onResendCode={handleResendCode}
      onSubmit={handleSubmit}
      phone={location.state?.phone}
    />
  )
}

export default CheckVerificationCodePage
