import React from 'react'
import Helmet from 'react-helmet'
import { Outlet, useLocation } from 'react-router-dom'

import FooterDiv from '@app/account/components/elements/FooterDiv'
import TermsAndPrivacyPolicy from '@app/account/components/elements/TermsAndPrivacyPolicy'
import useCustomer from '@app/configuration/hooks/useCustomer'
import styled from '@app/src/constants/theme'
import { useI18n } from '@app/configuration/contexts/I18nContext'

const MfaIndex: React.FC = () => {
  const { pageTitle } = useCustomer()
  const location = useLocation()
  const i18n = useI18n()

  let pageTitleSuffix = i18n.t('mfa.name')
  const pathParts = location.pathname.split('/mfa/')
  if (pathParts.length > 1) {
    const childPath = pathParts[1]
    switch (childPath) {
      case 'add_phone_number':
        pageTitleSuffix = i18n.t('mfa.sendVerificationCodePage.pageTitle')
        break
      case 'check_verification_code':
        pageTitleSuffix = i18n.t('mfa.checkVerificationCodePage.pageTitle')
        break
      default:
        break
    }
  }

  return (
    <React.Fragment>
      <Helmet title={`${pageTitle} - ${pageTitleSuffix}`} />
      <Wrapper>
        <Outlet />
      </Wrapper>
      <FooterDiv>
        <TermsAndPrivacyPolicy />
      </FooterDiv>
    </React.Fragment>
  )
}

const Wrapper = styled.div({
  '& .FormSubmit': {
    '& > button': {
      marginLeft: 0,
      marginTop: 10,
      width: '100%',
    },
    flexDirection: 'column-reverse',
  },
  '& > h1': {
    marginBottom: 20,
  },
  '& > p': {
    marginBottom: 30,
  },
  '.MfaChannel': {
    marginTop: 10,
    textAlign: 'left',
  },
  '.MuiAlert-root': {
    marginTop: 20,
    textAlign: 'left',
  },
  margin: '0 auto',
  maxWidth: 500,
  textAlign: 'center',
  width: '100%',
})

export default MfaIndex
