import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import MFA_SEND_VERIFICATION_CODE_MUTATION from '@app/account/graphql/MfaSendVerificationCodeMutation.gql'
import { useI18n } from '@app/configuration/contexts/I18nContext'
import {
  Mfa,
  MfaSendChannelEnum,
  MfaSendVerificationCodeMutation,
  MfaSendVerificationCodeMutationVariables,
} from '@app/graphql-types/Account'
import AddPhoneNumberForm from '@app/src/components/forms/Mfa/AddPhoneNumberForm'
import { useMutationResult } from '@app/src/hooks/useMutationResult'

const AddPhoneNumberPage = () => {
  const i18n = useI18n()
  const location = useLocation()
  const navigate = useNavigate()

  const defaultErrorMessage = i18n.t('mfa.sendCodeErrorMessage')

  const { mutate } = useMutationResult<
    MfaSendVerificationCodeMutation,
    MfaSendVerificationCodeMutationVariables,
    Mfa
  >({
    mutation: MFA_SEND_VERIFICATION_CODE_MUTATION,
    successField: 'mfaSendVerificationCode',
  })

  const handleSubmit = React.useCallback((formData: { phone: string; mfaChannel: MfaSendChannelEnum }, setErrorText: any) => {
    mutate({ mfaToken: location.state?.mfaToken, ...formData })
      .then(({ data, errors }) => {
        if (errors?.length) {
          const msg = errors[0].message || defaultErrorMessage
          setErrorText(msg)
          return
        }

        const mfaData = data?.mfaSendVerificationCode
        if (mfaData) {
          navigate(mfaData.redirectPath, {
            state: {
              mfaChannel: mfaData.mfaChannel,
              mfaToken: mfaData.mfaToken,
              phone: mfaData.phoneNumber,
            },
          })
        }
      })
      .catch((_serverError) => {
        setErrorText(defaultErrorMessage)
      })
  }, [defaultErrorMessage, location, mutate, navigate])

  return (
    <AddPhoneNumberForm
      mfaChannel={location.state?.mfaChannel}
      onSubmit={handleSubmit}
      phone={location.state?.phone}
    />
  )
}

export default AddPhoneNumberPage
