mutation MfaCheckVerificationCode(
  $clientId: String!
  $clientSecret: String!
  $code: String!
  $mfaChannel: MfaSendChannelEnum!
  $mfaToken: String!
  $phone: String!
) {
  mfaCheckVerificationCode(
    clientId: $clientId
    clientSecret: $clientSecret
    code: $code
    mfaChannel: $mfaChannel
    mfaToken: $mfaToken
    phone: $phone
  ) {
    accessToken
  }
}
