import { ApolloClient, ApolloLink, ApolloProvider } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { ConfigurationProvider } from '../configuration/contexts/ConfigurationContext'
import { I18nTranslationProvider } from '../configuration/contexts/I18nContext'
import '../init'
import ThemeManager from '../src/components/modules/ThemeManager'
import { cache, ensurePatientTypePolicies } from '../src/graphql/cache'
import '../src/helpers/newrelic'
import { AccountExceptionNotifier } from '../src/lib/ExceptionNotifier'
import preferredLanguageLink from '../src/networking/preferredLanguageLink'
import AddPhoneNumberPage from './components/pages/mfa/AddPhoneNumberPage'
import CheckVerificationCode from './components/pages/mfa/CheckVerificationCodePage'
import ForgotPasswordEmailSentPage from './components/pages/ForgotPasswordEmailSentPage'
import ForgotPasswordPage from './components/pages/ForgotPasswordPage'
import Layout from './components/pages/Layout'
import RegisterInvitationPage from './components/pages/RegisterInvitationPage'
import RegistrationPage from './components/pages/RegistrationPage'
import ResendInvitationPage from './components/pages/ResendInvitationPage'
import ResetPasswordPage from './components/pages/ResetPasswordPage'
import SignInPage from './components/pages/SignInPage'
import MfaIndex from '@app/account/components/pages/mfa/Index'

const errorLink = onError(({ graphQLErrors, networkError }: any) => {
  if (networkError) {
    // const { statusCode } = networkError
    // console.log('networkError', statusCode)
    return
  }
  if (graphQLErrors) {
    // const err = graphQLErrors[0]
    // console.log('graphQLErrors', err)
    return
  }
}) as any
// TODO: figure out why we need to explicitly grab and send this
const csrfTokenEl = document.querySelector('meta[name="csrf-token"]')
const uploadLink = createUploadLink({
  credentials: 'include',
  fetch,
  headers: {
    accept: 'application/json',
    'X-CSRF-TOKEN': (csrfTokenEl && csrfTokenEl.getAttribute('content')) ?? '',
  },
  uri: '/api/account/v2',
}) as any

const link = ApolloLink.from([
  errorLink,
  preferredLanguageLink,
  uploadLink,
]) as any
const client = new ApolloClient({
  cache,
  defaultOptions: {
    mutate: {
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    },
    watchQuery: {
      errorPolicy: 'all',
    },
  },
  link,
})

const AccountView = () => {
  React.useEffect(() => {
    // We don't have a ConfigurationContext.value.user on the Account pages
    ensurePatientTypePolicies(false)
  }, [])

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route
            path="/doctors/invitation/accept"
            element={<RegisterInvitationPage />}
          />
          <Route path="/invitation/resend" element={<ResendInvitationPage />} />
          <Route path="/mfa" element={<MfaIndex />}>
            <Route path="add_phone_number" element={<AddPhoneNumberPage />} />
            <Route
              path="check_verification_code"
              element={<CheckVerificationCode />}
            />
          </Route>
          <Route path="/password/new" element={<ForgotPasswordPage />} />
          <Route
            path="/password/sent"
            element={<ForgotPasswordEmailSentPage />}
          />
          <Route path="/password/edit" element={<ResetPasswordPage />} />
          <Route
            path="/patients/invitation/accept"
            element={<RegisterInvitationPage />}
          />
          <Route path="/register" element={<RegistrationPage />} />
          <Route path="/sign_in" element={<SignInPage />} />
          <Route path="/" element={<RegistrationPage />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  )
}

const Index: React.FC = () => {
  // override main web-app's non-scroll
  const overrideStyles = {
    body: {
      height: '100vh',
      overflow: 'scroll',
    },
    html: {
      height: 'auto',
      overflow: 'scroll',
    },
  }

  return (
    <ConfigurationProvider>
      <ThemeManager overrideStyles={overrideStyles}>
        <AccountExceptionNotifier>
          <I18nTranslationProvider>
            <ApolloProvider client={client}>
              <AccountView />
            </ApolloProvider>
          </I18nTranslationProvider>
        </AccountExceptionNotifier>
      </ThemeManager>
    </ConfigurationProvider>
  )
}

export default Index
